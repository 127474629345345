<script setup lang="ts"></script>

<template>
  <div>
    <Navbar />
    <slot />
  </div>
</template>

<style>
html,
body {
  background-color: var(--bg-primary) !important;
  overflow-x: hidden !important;
  color: var(--font-primary);
  padding: 0px;
  margin: 0px;
}
</style>
